<template>
  <div class="yz-inter-wrp">
    <erji-carousel></erji-carousel>
    <div class="ab-text">
      <div class="ab-title animate__bounceIn">
        <p>—— 国际资源 ——</p>
      </div>
      <div class="ab-desc animate__bounceIn">
        <p>
          <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
        </p>
      </div>
    </div>
    <div class="yz-honor-center">
      <div class="honors-con fl">
        <img src="../../../assets/images/inter01.png" />
        <p class="honor-text">
          2016年2月，并购欧洲第二大职业教育集团一法国DEMOS
        </p>
      </div>
      <div class="honors-con fr">
        <img src="../../../assets/images/inter02.jpg" />
        <p class="honor-text">2016年10月，并购法国38所精英大学之一的BBS</p>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
 .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
.yz-inter-wrp {
  //   width: 1200px;
  //   margin:0 auto;
  margin-bottom: 40px;
  .yz-honor-center {
    width: 1200px;
    margin: 0 auto;
    .honors-con {
      width: 48%;
      height: 400px;
      margin-top: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: 350px;
      }
      .honor-text {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
